import { ProjectImplWithStats } from '../models/Project';

const CURRENT_PROJECT_KEY = 'currentProject';
const CURRENT_STEP_KEY = 'currentStep';
export const saveProjectToStorage = (project: ProjectImplWithStats) => {
    try {
        const serializedProject = {
            ...project,
            created_at: project.created_at?.toISOString(),
            updated_at: project.updated_at?.toISOString(),
        };
        localStorage.setItem(CURRENT_PROJECT_KEY, JSON.stringify(serializedProject));
    } catch (error) {
        console.error('Error saving project to storage:', error);
    }
};

export const loadProjectFromStorage = (): ProjectImplWithStats | null => {
    try {
        const savedProject = localStorage.getItem(CURRENT_PROJECT_KEY);
        if (!savedProject) return null;

        const parsedProject = JSON.parse(savedProject);

        // Convert string dates back to Date objects
        if (parsedProject.created_at) {
            parsedProject.created_at = new Date(parsedProject.created_at);
        }
        if (parsedProject.updated_at) {
            parsedProject.updated_at = new Date(parsedProject.updated_at);
        }

        const project = new ProjectImplWithStats(parsedProject);
        return project;
    } catch (error) {
        console.error('Error loading project from storage:', error);
        return null;
    }
};

export const clearProjectFromStorage = () => {
    localStorage.removeItem(CURRENT_PROJECT_KEY);
};

export const saveCurrentStepToStorage = (step: number) => {
    localStorage.setItem(CURRENT_STEP_KEY, step.toString());
};

export const loadCurrentStepFromStorage = (): number | null => {
    const savedStep = localStorage.getItem(CURRENT_STEP_KEY);
    return savedStep ? parseInt(savedStep) : null;
};

export const clearCurrentStepFromStorage = () => {
    localStorage.removeItem(CURRENT_STEP_KEY);
};
