import React, { useState, useEffect } from 'react';
import AudioPlayerComponent from '../AudioPlayerComponent';
import 'react-h5-audio-player/lib/styles.css';
import styles from './PlayAndSave.module.css';
import sharedStyles from '../Shared.module.css';
import { generateTitle, saveProject } from '../../../utils/backendInterface';
import { ProjectImplWithStats } from '../../../models/Project';
import {
    clearCurrentStepFromStorage,
    clearProjectFromStorage,
} from '../../../utils/projectStateManager';

let defaultDisplayTitle = '';
if (window.origin && window.origin.includes('localhost')) {
    defaultDisplayTitle = 'Two Officers Walk Into a Bar';
}

type PlayAndSaveProps = {
    onForward: () => void;
    onBack: () => void;
    dss: ProjectImplWithStats;
    setDss: (dss: ProjectImplWithStats) => void;
};

const PlayAndSave = ({ onForward, onBack, dss, setDss }: PlayAndSaveProps) => {
    const [title, setTitleState] = useState(dss.title || defaultDisplayTitle);
    const [creator, setCreatorState] = useState(dss.creator || '');
    const [titleGenerated, setTitleGenerated] = useState(false);
    const dialogueData = dss ? dss.dialogueDataItems() : [];
    const canSave =
        title &&
        dss.scenes.length > 0 &&
        dss.music &&
        dialogueData.length > 0 &&
        dialogueData[0].audio_url;

    const saveToBackend = async () => {
        dss.title = title;
        const newDss = await saveProject(dss);
        setDss(newDss);
        clearProjectFromStorage(); // Clear local storage after successful save
        clearCurrentStepFromStorage();
        onForward();
    };

    useEffect(() => {
        const generateAndSetTitle = async () => {
            const generatedTitle = await generateTitle(dss);
            setTitle(generatedTitle);
        };
        if (!dss.title && !titleGenerated) {
            generateAndSetTitle().then(() => {
                setTitleGenerated(true);
            });
        }
    }, [dss]);

    const setTitle = (newTitle: string) => {
        setTitleState(newTitle);
        setDss(
            new ProjectImplWithStats({
                ...dss,
                title: newTitle,
            }),
        );
    };

    const setCreator = (newCreator: string) => {
        setCreatorState(newCreator);
        const newDss = new ProjectImplWithStats({
            ...dss,
        });
        newDss.creator = newCreator;
        setDss(newDss);
    };

    const onProjectStatsUpdatedInAudioPlayer = (updatedProject: ProjectImplWithStats) => {
        setDss(
            new ProjectImplWithStats({
                ...dss,
                ...updatedProject,
            }),
        );
    };

    return (
        <form className={sharedStyles.form}>
            <p className={styles.informationText}>
                If you click save below your movie will be added to the Play section of this
                experiment for others to view also. Don’t want to save? Just tap the X above to exit
                without saving.
            </p>
            <AudioPlayerComponent dss={dss} onProjectSaved={onProjectStatsUpdatedInAudioPlayer} />
            <div className={styles.titleForm}>
                <div className={sharedStyles.formField}>
                    <label htmlFor="creator" className={sharedStyles.formLabel}>
                        CREATOR NAME (optional)
                    </label>
                    <input
                        type="text"
                        id="creator"
                        className={sharedStyles.formInput}
                        aria-label="Creator Name"
                        value={creator}
                        onChange={e => setCreator(e.target.value)}
                    />
                </div>
                <div className={sharedStyles.formField}>
                    <label htmlFor="displayTitle" className={sharedStyles.formLabel}>
                        DISPLAY TITLE
                    </label>
                    <input
                        type="text"
                        id="displayTitle"
                        className={sharedStyles.formInput}
                        aria-label="Display Title"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                </div>
            </div>

            <div className={sharedStyles.buttonRow}>
                <button
                    type="button"
                    className={`${sharedStyles.button} ${sharedStyles['active-secondary']}`}
                    onClick={onBack}
                >
                    <span className={sharedStyles.buttonText}>GO BACK</span>
                </button>
                <button
                    type="button"
                    className={`${sharedStyles.button} ${sharedStyles['active-primary']}`}
                    onClick={saveToBackend}
                    disabled={!canSave}
                >
                    <span className={sharedStyles.buttonText}>SAVE</span>
                    <div className={sharedStyles.buttonIcon}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={sharedStyles.buttonIconImage}
                        >
                            <path
                                id="Exit"
                                d="M8.5 1L16.5 9M16.5 9L8.5 17M16.5 9H1"
                                strokeLinecap="round"
                            />
                        </svg>
                    </div>
                </button>
            </div>
        </form>
    );
};

export default PlayAndSave;
