import React, { useState } from 'react';
import styles from './ReviewScript.module.css';
import sharedStyles from '../Shared.module.css';
import ReactMarkdown from 'react-markdown';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import { ProjectImplWithStats } from '../../../models/Project';

type ReviewScriptProps = {
    onForward: () => void;
    onBack: () => void;
    dss: ProjectImplWithStats;
    loading: boolean;
};
const ReviewScript = ({ onForward, onBack, dss, loading }: ReviewScriptProps) => {
    const [activeTab, setActiveTab] = useState('SCRIPT');

    const handleTabSwitch = tab => {
        setActiveTab(tab);
    };

    const canContinue = dss.scenes.length > 0 && !loading;

    return (
        <form className={sharedStyles.form}>
            <div>
                <div className={styles.menuBar}>
                    <ul className={styles.menuList}>
                        <li
                            className={`${styles.menuItem} ${
                                activeTab === 'SCRIPT' ? styles.active : ''
                            }`}
                            onClick={() => handleTabSwitch('SCRIPT')}
                        >
                            SCRIPT
                        </li>
                        <li
                            className={`${styles.menuItem} ${
                                activeTab === 'DSS' ? styles.active : ''
                            }`}
                            onClick={() => handleTabSwitch('DSS')}
                        >
                            DSS
                        </li>
                    </ul>
                </div>

                <div className={styles.scriptContainer}>
                    {activeTab === 'SCRIPT' ? (
                        <div className={styles.textareaWrapper}>
                            <ReactMarkdown className={styles.scriptTextarea}>
                                {dss.scriptText}
                            </ReactMarkdown>
                        </div>
                    ) : (
                        <div className={styles.textareaWrapper}>
                            {dss ? (
                                <JsonView src={dss} className={styles.scriptTextarea} />
                            ) : (
                                <p>Loading DSS...</p>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div className={sharedStyles.buttonRow}>
                <button
                    type="button"
                    className={`${sharedStyles.button} ${sharedStyles['active-secondary']}`}
                    onClick={onBack}
                >
                    <span className={sharedStyles.buttonText}>GO BACK</span>
                </button>
                <button
                    type="submit"
                    className={`${sharedStyles.button} ${
                        canContinue ? sharedStyles['active-primary'] : sharedStyles['inactive']
                    }`}
                    disabled={!canContinue}
                    onClick={onForward}
                    title={!canContinue ? "We're still loading. Please wait." : ''}
                >
                    <span className={sharedStyles.buttonText}>SET THE VIBE</span>
                    <div className={sharedStyles.buttonIcon}>
                        {!loading ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={sharedStyles.buttonIconImage}
                            >
                                <path
                                    id="Exit"
                                    d="M8.5 1L16.5 9M16.5 9L8.5 17M16.5 9H1"
                                    strokeLinecap="round"
                                />
                            </svg>
                        ) : (
                            <svg
                                fill="hsl(228, 97%, 42%)"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                className={sharedStyles.buttonIconImage}
                            >
                                <path
                                    d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                                    opacity=".25"
                                />
                                <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z">
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        dur="0.75s"
                                        values="0 12 12;360 12 12"
                                        repeatCount="indefinite"
                                    />
                                </path>
                            </svg>
                        )}
                    </div>
                </button>
            </div>
        </form>
    );
};

export default ReviewScript;
