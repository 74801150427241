import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MediaGrid from './MediaGrid';
import ProjectPlay from './ViewContent'; // Assuming you have this component
import styles from './PlayContent.module.css';
import { loadProjects } from '../../../utils/backendInterface';
import { ProjectImplWithStats } from '../../../models/Project';

function PlayContent() {
    const [searchParams] = useSearchParams();
    const [projects, setProjects] = useState<ProjectImplWithStats[]>([]);
    const navigate = useNavigate();

    const id = searchParams.get('id');
    const onProjectStatsUpdated = (updatedProject: ProjectImplWithStats, index: number) => {
        setProjects(prevProjects => {
            const newProjects = [...prevProjects];
            newProjects[index] = updatedProject;
            return newProjects;
        });
    };

    useEffect(() => {
        async function loadProjectsWrapper() {
            const projects = await loadProjects();
            setProjects(projects);
        }

        loadProjectsWrapper();
    }, []);

    if (id) {
        return <ProjectPlay />;
    }

    return (
        <div className={styles.playContent}>
            <p className={styles.instruction}>
                See what others have created or{' '}
                <span
                    className={styles.createLink}
                    onClick={() => navigate('/first-date/create')}
                    style={{ cursor: 'pointer' }}
                >
                    create your own
                </span>
            </p>
            <MediaGrid projects={projects} onProjectStatsUpdated={onProjectStatsUpdated} />
        </div>
    );
}

export default PlayContent;
