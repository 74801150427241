import React, { useEffect, useRef } from 'react';
import sharedStyles from '../Shared.module.css';
import { getMusicOptions, MusicOption } from '../../../models/Project';

export const defaultSelectedMusicOption = () => {
    const options = getMusicOptions();
    return options[Math.floor(Math.random() * options.length)];
};

type SetTheVibeProps = {
    onForward: () => void;
    onBack: () => void;
    selectedMusicOption: MusicOption | null;
    setSelectedMusicOption: (music: MusicOption) => void;
    loading: boolean;
    dss: any;
};

const SetTheVibe = ({
    onForward,
    onBack,
    selectedMusicOption,
    setSelectedMusicOption,
    loading,
    dss,
}: SetTheVibeProps) => {
    const [musicOptions] = React.useState(getMusicOptions());
    const [isPlaying, setIsPlaying] = React.useState(false);
    const audioRef = useRef<HTMLAudioElement>(null);
    const canContinue = dss.scenes.length > 0 && dss.music && !loading;

    const handleMusicSelect = async (music: MusicOption) => {
        try {
            // If clicking the same track that's currently selected
            if (selectedMusicOption?.url === music.url && audioRef.current) {
                if (isPlaying) {
                    audioRef.current.pause();
                    setIsPlaying(false);
                } else {
                    await audioRef.current.play();
                    setIsPlaying(true);
                }
                return;
            }

            // Stop and cleanup current audio if it exists
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.remove();
                audioRef.current = null;
            }

            // Update selected music option
            setSelectedMusicOption(music);

            // Create and setup new audio element
            const newAudio = new Audio();
            newAudio.src = music.url;
            newAudio.preload = 'auto';
            // Add these for iOS
            (newAudio as any).playsInline = true;
            newAudio.controls = false;

            // Add ended event listener
            newAudio.addEventListener('ended', () => {
                setIsPlaying(false);
            });

            try {
                // On iOS, we need to load() before play()
                await newAudio.load();
                await new Promise((resolve, reject) => {
                    newAudio.addEventListener('canplaythrough', resolve, { once: true });
                    newAudio.addEventListener('error', reject, { once: true });
                });

                // Set the new audio element
                audioRef.current = newAudio;

                // Start playing the new track and update state
                await newAudio.play();
                setIsPlaying(true);
            } catch (error) {
                console.error('Error loading audio:', error);
                throw error;
            }
        } catch (error) {
            console.error('Error handling music selection:', error);
            setIsPlaying(false);
            if (audioRef.current) {
                audioRef.current.remove();
                audioRef.current = null;
            }
        }
    };

    // Initialize default music option when component mounts
    useEffect(() => {
        if (!selectedMusicOption) {
            const defaultMusic = defaultSelectedMusicOption();
            setSelectedMusicOption(defaultMusic);
        }
        setIsPlaying(false);
        // Cleanup any existing audio before mounting
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.remove();
            audioRef.current = null;
        }

        // Only create audio if we have a selected option
        if (selectedMusicOption) {
            const newAudio = new Audio();
            newAudio.src = selectedMusicOption.url;
            newAudio.preload = 'auto';
            (newAudio as any).playsInline = true;
            newAudio.controls = false;

            newAudio.addEventListener('ended', () => {
                setIsPlaying(false);
            });

            audioRef.current = newAudio;
        }
    }, [selectedMusicOption]);

    // Cleanup effect when component unmounts
    useEffect(() => {
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.remove();
                audioRef.current = null;
            }
        };
    }, []);

    return (
        <form className={sharedStyles.form}>
            <div className={sharedStyles.optionCloud}>
                <h3 className={sharedStyles.optionCloudTitle}>BACKGROUND MUSIC STYLE</h3>
                <div className={sharedStyles.optionCloudList}>
                    {musicOptions.map((music, index) => {
                        const isSelected = selectedMusicOption?.name === music.name;
                        return (
                            <button
                                type="button"
                                key={index}
                                className={`music-option ${sharedStyles.option} ${
                                    isSelected ? sharedStyles['selected'] : ''
                                }`}
                                onClick={() => handleMusicSelect(music)}
                                style={{
                                    position: 'relative',
                                    opacity: isSelected ? (isPlaying ? 1 : 0.7) : 1,
                                }}
                            >
                                <span className={sharedStyles.optionTitle}>{music.name}</span>
                                {isSelected && (
                                    <span className={sharedStyles.playPauseButton}>
                                        {isPlaying ? (
                                            <svg width="16" height="16" viewBox="0 0 16 16">
                                                <rect
                                                    x="3"
                                                    y="2"
                                                    width="3"
                                                    height="12"
                                                    fill="currentColor"
                                                />
                                                <rect
                                                    x="10"
                                                    y="2"
                                                    width="3"
                                                    height="12"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        ) : (
                                            <svg width="16" height="16" viewBox="0 0 16 16">
                                                <path d="M4 2l10 6-10 6V2z" fill="currentColor" />
                                            </svg>
                                        )}
                                    </span>
                                )}
                            </button>
                        );
                    })}
                </div>
            </div>

            <div className={sharedStyles.buttonRow}>
                <button
                    type="button"
                    className={`${sharedStyles.button} ${sharedStyles['active-secondary']}`}
                    onClick={onBack}
                >
                    <span className={sharedStyles.buttonText}>GO BACK</span>
                </button>
                <button
                    type="submit"
                    className={`${sharedStyles.button} ${sharedStyles['active-primary']}`}
                    onClick={onForward}
                    disabled={!canContinue}
                    title={!canContinue ? "We're generating your scene's audio. Please wait." : ''}
                >
                    <span className={sharedStyles.buttonText}>PLAY AND SAVE</span>
                    <div className={sharedStyles.buttonIcon}>
                        {!loading ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={sharedStyles.buttonIconImage}
                                width="30px"
                                height="30px"
                                viewBox="0 0 24 24"
                            >
                                <g transform="scale(1.2) translate(2,1.5)">
                                    <path
                                        id="Exit"
                                        d="M8.5 1L16.5 9M16.5 9L8.5 17M16.5 9H1"
                                        strokeLinecap="round"
                                        strokeWidth="1.3"
                                    />
                                </g>
                            </svg>
                        ) : (
                            <svg
                                fill="hsl(228, 97%, 42%)"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                className={sharedStyles.buttonIconImage}
                            >
                                <path
                                    d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                                    opacity=".25"
                                />
                                <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z">
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        dur="0.75s"
                                        values="0 12 12;360 12 12"
                                        repeatCount="indefinite"
                                    />
                                </path>
                            </svg>
                        )}
                    </div>
                </button>
            </div>
        </form>
    );
};

export default SetTheVibe;
