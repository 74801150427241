import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './FindingsContent.module.css';
import sharedStyles from '../Shared.module.css';
import { sendFeedback as sendFeedbackToBackend } from '../../../utils/backendInterface';

const findingContent = [
    {
        title: 'Expressive Voices',
        subtitle: 'Squeezing the most out of early AI voices',
        date: '11.10.24',
        finding: `
The current generation of AI voices are both difficult to control,
and highly variable in quality. They are trained on mostly audiobook
narration as opposed to two-person dialog, which means they lack the
nuance of expression & informality of human conversation. 

Generally they feel like they are talking **at** each other, rather than
**with** each other. We experimented with as many voices as we could find,
and added in a few tricks to coax more natural sounding dialog.

**Key improvements we discovered:**

1. Adding audiobook-style modifiers like: *"I'm here! - Jane said excitedly"*
2. Adding context in the form of the previous line of dialog
        
We plan on integrating much more dynamic and conversational voices
as soon as possible, along with the ability to control emotion, speed,
and expressiveness.`,
    },
    {
        title: 'The right amount of choice',
        subtitle: 'Too many options can overwhelm, too few can bore.',
        date: '11.01.24',
        finding: `
A main goal of Labs experiments is to isolate particular
creative variables. For this experiment, we wanted to
evaluate the minimal number of choices that still
result in a fun experience.

We started with just the star signs, but this felt just a tad impersonal.
However, adding just a few key choices dramatically increased engagement:

- Character names
- Story title
- Style influence
- Background music

This combination provided enough personalization without overwhelming users,
resulting in a much more **personal experience**.`,
    },
    {
        title: 'Creating a working script',
        subtitle: 'Out of the box GPT scripts were not relatable, and needed tuning.',
        date: '10.28.24',
        finding: `
To explore AI **voice acting** we needed an easy and 
fun way for creators to generate a short, dialog heavy 
script they could work with. Our first attempts produced an overly energetic, 
overly witty dialog style and we experimented with a 
range of prompts to increase the chances of a 
relatable, entertaining script every time.

One of the most effective levers for this was to give the AI a 
writing style to emulate, with **British dark comedy** 
giving pretty good results. We added a **script style** 
field to expose this to our creators.`,
    },
].sort((a, b) => {
    const dateA = new Date(
        `20${a.date
            .split('.')
            .reverse()
            .join('-')}`,
    );
    const dateB = new Date(
        `20${b.date
            .split('.')
            .reverse()
            .join('-')}`,
    );
    return dateB.getTime() - dateA.getTime(); // Newest first
});

function FindingsContent() {
    const [feedbackSent, setFeedbackSent] = useState(false);
    const [feedbackError, setFeedbackError] = useState('');
    const [fromName, setFromName] = useState('');
    const [fromEmail, setFromEmail] = useState('');

    const sendFeedback = async () => {
        const feedback = (document.getElementById('feedbackContainer') as HTMLTextAreaElement)
            .value;
        if (!feedback.trim()) {
            alert('Please enter your feedback before sending');
            return;
        }

        try {
            await sendFeedbackToBackend({
                feedback: feedback.trim(),
                from_name: fromName.trim() || '',
                from_email: fromEmail.trim() || '',
            });
            (document.getElementById('feedbackContainer') as HTMLTextAreaElement).value = '';
            setFromName('');
            setFromEmail('');
            setFeedbackSent(true);
            return true;
        } catch (error) {
            console.error('Error sending feedback:', error);
            setFeedbackError('Failed to send feedback. Please try again later.');
            return false;
        }
    };

    // Reset states when component unmounts
    useEffect(() => {
        return () => {
            setFeedbackSent(false);
            setFeedbackError('');
        };
    }, []);

    return (
        <div className={styles.findingsContent}>
            <p className={styles.description}>
                Exploring whether entertaining characters and a believable rom-com genre scene can
                be AI generated based on simple inputs. Script and audio only.
            </p>

            <div className={styles.findingsContainer}>
                {findingContent.map((finding, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && <hr className={styles.separator} />}
                        <div className={styles.findingCard}>
                            <div className={styles.findingHeader}>
                                <h2 className={styles.findingTitle}>{finding.title}</h2>
                                <span className={styles.findingDate}>{finding.date}</span>
                            </div>
                            <h3 className={styles.findingSubtitle}>{finding.subtitle}</h3>
                            <div className={styles.findingText}>
                                <ReactMarkdown>{finding.finding}</ReactMarkdown>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>

            <p className={styles.description} style={{ fontStyle: 'italic' }}>
                Send us a note below about what worked / did not work, etc. and we will include your
                feedback to our findings.
            </p>
            <div className={styles.feedbackContainer}>
                <div className={styles.inputField}>
                    <label htmlFor="fromEmail" className={styles.feedbackLabel}>
                        YOUR EMAIL (OPTIONAL, WILL NOT BE SHARED)
                    </label>
                    <input
                        id="fromEmail"
                        type="email"
                        className={styles.feedbackTextInput}
                        value={fromEmail}
                        onChange={e => setFromEmail(e.target.value)}
                        aria-label="Your Email"
                    />
                </div>
                <label htmlFor="feedbackContainer" className={styles.feedbackLabel}>
                    YOUR FEEDBACK
                </label>
                {feedbackSent && (
                    <div
                        className={styles.feedbackBanner}
                        style={{
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            padding: '8px',
                            marginBottom: '10px',
                            borderRadius: '4px',
                        }}
                    >
                        Feedback sent successfully!
                    </div>
                )}
                {feedbackError && (
                    <div
                        className={styles.feedbackBanner}
                        style={{
                            backgroundColor: '#f44336',
                            color: 'white',
                            padding: '8px',
                            marginBottom: '10px',
                            borderRadius: '4px',
                        }}
                    >
                        {feedbackError}
                    </div>
                )}
                <textarea
                    id="feedbackContainer"
                    className={styles.feedbackTextarea}
                    aria-label="Feedback"
                />
            </div>
            <div className={sharedStyles.buttonRow}>
                <button
                    type="submit"
                    className={`${sharedStyles.button} ${sharedStyles['active-primary']}`}
                    onClick={sendFeedback}
                >
                    <span className={sharedStyles.buttonText}>SEND</span>
                    <div className={sharedStyles.buttonIcon}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={sharedStyles.buttonIconImage}
                        >
                            <path
                                id="Exit"
                                d="M8.5 1L16.5 9M16.5 9L8.5 17M16.5 9H1"
                                strokeLinecap="round"
                            />
                        </svg>
                    </div>
                </button>
            </div>
        </div>
    );
}

export default FindingsContent;
