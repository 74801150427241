import React, { useState, useEffect } from 'react';
import { useSearchParams, useLocation, useNavigate, Link } from 'react-router-dom';
import AudioPlayerComponent from '../AudioPlayerComponent';
import 'react-h5-audio-player/lib/styles.css';
import styles from './ViewContent.module.css';
import sharedStyles from '../Shared.module.css';
import { ProjectImplWithStats } from '../../../models/Project';
import { loadProject } from '../../../utils/backendInterface';

const ProjectPlay = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const [project, setProject] = useState<ProjectImplWithStats | null>(
        location.state?.dss || null,
    );
    const [loading, setLoading] = useState(!project); // Skip loading if `dss` is already available
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const projectId = searchParams.get('id');
        if (!projectId) {
            setError(true);
            setLoading(false);
            return;
        }

        const fetchProject = async () => {
            try {
                const loadedProject = await loadProject(projectId);
                if (!loadedProject) {
                    setError(true);
                } else {
                    setProject(new ProjectImplWithStats(loadedProject));
                }
            } catch (e) {
                console.error('Error loading project:', e);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchProject();
    }, [searchParams]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error || !project) {
        return <div>Error loading project. Please try again later.</div>;
    }

    return (
        <div>
            <div className={styles.playHeader}>
                <h3 className={styles.playTitle}>{project.title}</h3>
                <Link className={styles.returnLink} to="/first-date/play">
                    Back to gallery
                </Link>
            </div>

            <AudioPlayerComponent
                dss={project}
                onProjectSaved={() => {
                    console.log('played');
                }}
            />

            <div className={styles.projectInfo}>
                {project.pairing && (
                    <p className={styles.cardPairing}>{project?.pairing.replace(/and/g, 'v.')}</p>
                )}
                {project.creator && <p className={styles.cardCreator}>By {project.creator}</p>}
                {project.scriptStyle && (
                    <p className={styles.cardCreator}>{project.scriptStyle} style</p>
                )}
                <p className={styles.cardStats}>
                    <span className={styles.statBold}>{project.days || 0} </span>
                    <span className={styles.statNormal}>days </span>
                    <span className={styles.statBold}>{project.starts || 0} </span>
                    <span className={styles.statNormal}>starts </span>
                    <span className={styles.statBold}>{project.plays || 0} </span>
                    <span className={styles.statNormal}>views</span>
                </p>
            </div>
        </div>
    );
};

export default ProjectPlay;
