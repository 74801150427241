import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RouteConfig } from '../routes/routes';

// Define the context type
interface BackgroundContextType {
    backgroundColor: string;
    setManualBackground: (color: string | null) => void;
}

const BackgroundContext = createContext<BackgroundContextType | undefined>(undefined);

export const useBackground = () => {
    const context = useContext(BackgroundContext);
    if (!context) {
        throw new Error('useBackground must be used within a BackgroundProvider');
    }
    return context;
};

const findRouteBackground = (
    pathname: string,
    routes: RouteConfig[],
    parentColor?: string,
): string => {
    for (const route of routes) {
        const pathPattern = new RegExp(
            `^${route.path.replace('/*', '(/.*)?').replace(/\//g, '\\/')}$`,
        );

        if (pathPattern.test(pathname)) {
            if (route.children) {
                const childColor = findRouteBackground(
                    pathname,
                    route.children as RouteConfig[],
                    route.backgroundColor,
                );
                return childColor || route.backgroundColor;
            }
            return route.backgroundColor;
        }
    }
    return parentColor || '#3D4253';
};

export const BackgroundProvider: React.FC<{
    children: React.ReactNode;
    routes: RouteConfig[];
}> = ({ children, routes }) => {
    const location = useLocation();
    const [manualBackground, setManualBackground] = useState<string | null>(null);
    const [backgroundColor, setBackgroundColor] = useState(
        findRouteBackground(location.pathname, routes),
    );

    useEffect(() => {
        // Route change overrides manual background
        const routeBackground = findRouteBackground(location.pathname, routes);
        setBackgroundColor(routeBackground);
        setManualBackground(null);
    }, [location.pathname, routes]);

    useEffect(() => {
        if (manualBackground !== null) {
            setBackgroundColor(manualBackground);
        }
    }, [manualBackground]);

    const value = {
        backgroundColor,
        setManualBackground,
    };

    return <BackgroundContext.Provider value={value}>{children}</BackgroundContext.Provider>;
};
