import React, { useState, useEffect } from 'react';
import styles from './CreateContent.module.css';
import PairUp from './PairUp';
import ReviewScript from './ReviewScript';
import {
    saveProjectToStorage,
    loadProjectFromStorage,
    loadCurrentStepFromStorage,
    saveCurrentStepToStorage,
    clearCurrentStepFromStorage,
} from '../../../utils/projectStateManager';
import SetTheVibe, { defaultSelectedMusicOption } from './SetTheVibe';
import PlayAndSave from './PlayAndSave';
import {
    parseCharacters,
    parseDialogueFromScript,
    generateAudioWithParsedDialogue,
} from '../../../utils/structureScript';
import { allStarSigns, StarSign } from '../../../utils/starSignInformation';
import {
    MusicOption,
    SceneItemBase,
    DialogueImpl,
    Scene,
    Project,
    ProjectImplWithStats,
} from '../../../models/Project';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loadProject } from '../../../utils/backendInterface';
import { clearProjectFromStorage } from '../../../utils/projectStateManager';

const defaultFirstPersonSign = allStarSigns[0];
const defaultSecondPersonSign = allStarSigns[1];

type scriptModifierFn = (script: string[]) => string[];
type setDssFn = (prevDss: ProjectImplWithStats) => ProjectImplWithStats;
type setCurrentStepFn = (prevStep: number) => number;

function CreateContent() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [currentStep, setCurrentStepRaw] = useState(() => {
        const _currentStep = loadCurrentStepFromStorage();
        return _currentStep || 1;
    });
    const setCurrentStep = (fnOrNumber: setCurrentStepFn | number) => {
        setCurrentStepRaw(prevStep => {
            let newStep: number;
            if (typeof fnOrNumber === 'function') {
                newStep = fnOrNumber(prevStep);
            } else {
                newStep = fnOrNumber;
            }
            saveCurrentStepToStorage(newStep);
            return newStep;
        });
    };
    const [dss, setDss] = useState<ProjectImplWithStats>(() => {
        const savedProject = loadProjectFromStorage();
        return (
            savedProject ||
            new ProjectImplWithStats({
                scenes: [],
                characters: [],
                extra_data: {
                    firstPersonSign: defaultFirstPersonSign.name,
                    secondPersonSign: defaultSecondPersonSign.name,
                },
            } as Project)
        );
    });
    const setDssWrapper = (newDss: ProjectImplWithStats) => {
        setDss(_ => {
            saveProjectToStorage(newDss);
            return newDss;
        });
    };
    const setExtraDataField = (field: string, value: any) => {
        const newDss = new ProjectImplWithStats({ ...dss });
        if (!newDss.extra_data) {
            newDss.extra_data = {};
        }
        newDss.extra_data[field] = value;
        setDssWrapper(newDss);
    };

    // Save project state when it changes and we're past step 1
    useEffect(() => {
        if (currentStep > 1) {
            if (!dss.script || dss.script.length === 0) {
                setCurrentStep(1);
                saveCurrentStepToStorage(1);
            } else {
                saveCurrentStepToStorage(currentStep);
            }
            saveProjectToStorage(dss);
        }
    }, [dss, currentStep]);

    const setSelectedMusicOption = (music: MusicOption) => {
        setExtraDataField('music', music);
    };
    const [scriptUpdated, setScriptUpdated] = useState(0);
    const navigate = useNavigate();

    // Clear stored project when component unmounts
    useEffect(() => {
        return () => {
            saveCurrentStepToStorage(1);
            clearProjectFromStorage();
        };
    }, []);
    const [searchParams] = useSearchParams();

    const setScript = (scriptOrFn: string[] | scriptModifierFn) => {
        let script: string[];
        if (typeof scriptOrFn === 'function') {
            script = scriptOrFn(dss.script);
        } else {
            script = scriptOrFn;
        }
        const newDss = new ProjectImplWithStats({ ...dss });
        newDss.scenes = script.map(
            (sceneText, index) => ({ text: sceneText, items: dss.scenes[index]?.items } as Scene),
        );
        setDssWrapper(newDss);
    };

    const nextStep = () => {
        setCurrentStep(prevStep => Math.min(prevStep + 1, 4));
    };

    const previousStep = () => {
        setCurrentStep(prevStep => Math.max(prevStep - 1, 1));
    };

    const redirectToPlayView = () => {
        navigate('/first-date/play');
    };

    const handleFinishPairUp = () => {
        nextStep();
    };

    useEffect(() => {
        const projectId = searchParams.get('id');
        if (projectId) {
            const loadExistingProject = async () => {
                try {
                    setLoading(true);
                    clearProjectFromStorage(); // Clear any stored project when loading from URL
                    clearCurrentStepFromStorage();
                    const loadedProject = await loadProject(projectId);
                    if (!loadedProject) {
                        setError(true);
                        redirectToPlayView();
                        return;
                    }
                    setDss(_ => {
                        return new ProjectImplWithStats({ ...loadedProject });
                    });
                    setCurrentStep(4);
                } catch (error) {
                    console.error('Error loading project:', error);
                    setError(true);
                } finally {
                    setLoading(false);
                }
            };

            loadExistingProject();
        }
    }, [searchParams]);

    const fetchCharacters = async (dss: ProjectImplWithStats) => {
        const characters = await parseCharacters(dss.scriptText, setError);
        dss.characters = characters;
        return dss;
    };

    const fetchDialogues = async (dss: ProjectImplWithStats): Promise<ProjectImplWithStats> => {
        if (!dss.characters) {
            console.error('Characters must be set before fetching dialogues.');
            throw new Error('Characters must be set before fetching dialogues.');
        }
        const dialoguePromises = dss.scenes.map(async (scene, index) => {
            try {
                const parsedDialogueItems = await parseDialogueFromScript(
                    scene.text,
                    dss.characters,
                    setError,
                );
                dss.scenes[index].items = parsedDialogueItems;
            } catch (err) {
                throw new Error(`Error parsing dialogue: ${err}`);
            }
        });

        await Promise.all(dialoguePromises);
        return dss;
    };

    const fetchDialogAudio = async (localDss: ProjectImplWithStats) => {
        if (!localDss.scenes || localDss.scenes.length === 0 || !localDss.characters) {
            throw new Error('Dialogues must be set before fetching audio.');
        }
        try {
            const audioPromises = localDss.scenes.map(async scene => {
                return generateAudioWithParsedDialogue(
                    scene.items.filter(
                        (item: SceneItemBase) => item.type === 'Dialogue',
                    ) as DialogueImpl[],
                    localDss.characters || [],
                    setError,
                );
            });

            const audioResults = await Promise.all(audioPromises);
            localDss.scenes.forEach((scene, index) => {
                scene.items = audioResults[index];
            });
            return localDss;
        } catch (err) {
            throw new Error(`Error fetching dialog audio: ${err}`);
        }
    };

    const getData = async () => {
        try {
            setLoading(true);
            // Step 1: Fetch Characters
            const dssWithCharacters = await fetchCharacters(dss);
            // Step 2: Fetch Dialogues (using fetched characters)
            const dssWithDialogues = await fetchDialogues(dssWithCharacters);
            // Step 3: Fetch Dialog Audio (using fetched dialogues and characters)
            const dssWithAudio = await fetchDialogAudio(dssWithDialogues);
            setDssWrapper(dssWithAudio);
            setLoading(false);
        } catch (error) {
            console.error('Error in getData:', error);
            setError(true);
            setLoading(false);
        }
    };

    const reviewScriptOnForward = async () => {
        nextStep();
    };

    const setTheVibeOnForward = async () => {
        const dialogueData = dss ? dss.dialogueDataItems() : [];
        if (scriptUpdated > 0 || dialogueData.length === 0) {
            await getData();
            setScriptUpdated(0);
        }
        nextStep();
    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return (
                    <>
                        <h2 className={styles.stepTitle}>
                            Step 1: <span style={{ fontWeight: 400 }}>Pair up</span>
                        </h2>
                        <p className={styles.experimentInfo}>
                            Name each character, select their star sign (and fatal red flag), and
                            set your script writing style.
                        </p>
                        <PairUp
                            onForward={handleFinishPairUp}
                            setScript={setScript}
                            setScriptUpdated={setScriptUpdated}
                            setLoading={setLoading}
                            loading={loading}
                            dss={dss}
                            setExtraDataField={setExtraDataField}
                        />
                    </>
                );
            case 2:
                return (
                    <>
                        <h2 className={styles.stepTitle}>
                            Step 2: <span style={{ fontWeight: 400 }}>Review Script</span>
                        </h2>
                        <p className={styles.experimentInfo}>
                            See if your two characters clicked. Or, click the back button and adjust
                            your choices.
                        </p>
                        <ReviewScript
                            onForward={reviewScriptOnForward}
                            onBack={previousStep}
                            dss={dss}
                            loading={loading}
                        />
                    </>
                );
            case 3:
                return (
                    <>
                        <h2 className={styles.stepTitle}>
                            Step 3: <span style={{ fontWeight: 400 }}>Set the Vibe</span>
                        </h2>
                        <p className={styles.experimentInfo}>
                            What’s a drama without a soundtrack. Select from the styles below.
                        </p>
                        <SetTheVibe
                            onForward={setTheVibeOnForward}
                            onBack={previousStep}
                            selectedMusicOption={dss.music}
                            setSelectedMusicOption={setSelectedMusicOption}
                            loading={loading}
                            dss={dss}
                        />
                    </>
                );
            case 4:
                return (
                    <>
                        <h2 className={styles.stepTitle}>
                            Step 4: <span style={{ fontWeight: 400 }}>Play and Save</span>
                        </h2>
                        <p className={styles.experimentInfo}>
                            Hear your performance come to life. Will this romance pass the ‘Turing
                            test’ of time?
                        </p>
                        <PlayAndSave
                            onForward={redirectToPlayView}
                            onBack={previousStep}
                            dss={dss}
                            setDss={setDssWrapper}
                        />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div
            className={styles.createContent}
            data-testid="dss"
            data-music-name={dss.extra_data?.music?.name || ''}
            data-music-url={dss.extra_data?.music?.url || ''}
            data-creator={dss.creator || ''}
            data-title={dss.title || ''}
        >
            <p className={styles.experimentInfo}>
                Exploring whether entertaining characters and a believable rom-com genre scene can
                be AI generated based on simple inputs. Script and audio only.
            </p>
            {renderStepContent()}
        </div>
    );
}

export default CreateContent;
