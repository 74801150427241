import { StarSign } from './starSignInformation';

export const getCharacterPrompt = (
    firstPersonName: string,
    firstPersonSign: StarSign,
    secondPersonName: string,
    secondPersonSign: StarSign,
) =>
    `<Characters> \nThe characters are ${firstPersonName}, who is a ${firstPersonSign.name} (negative qualities: ${firstPersonSign.redFlag}) and ${secondPersonName}, who is a ${secondPersonSign.name} (negative qualities: ${secondPersonSign.redFlag}). \n</Characters>\n`;

export const getTitlePrompt = (scenes: string[]) => {
    return `Generate a title for the script. The title should be a short 2-5 word phrase that captures the essence of the script. DO NOT MENTION STAR SIGNS OR ASTROLOGY IN THE TITLE. DO NOT ADD QUOTES AROUND THE TITLE. <script>${scenes.join(
        '\n\nNEXT SCENE\n\n',
    )}</script> \n\nTitle:`;
};

export const getSingleSceneSystem = style => `You are the director and writer of a dating reality show scene where two individuals meet for a first date. Write a ${style} style script of this scene. 
Based on their star sign, pick an appropriate strongly negative characteristic for each person, e.g. controlling, insecure, self-obsessed, etc. 
At the beginning of the scene each person should introduce themselves and speak like a completely normal, well-balanced person and this negative characteristic should be completely hidden. As the scene unfolds the negative characteristic will start to subtly reveal itself in little mannerisms, subtle details of recounted stories, or through little details of the conversation.
`;

export const getSingleSceneUser = (sign1: StarSign, sign2: StarSign) => {
    const firstPrompt = `Establish the scene.
The characters arrive at slightly different times, introduce themselves and order drinks. 
Have one character initiate a very brief what is your star sign conversation. The conversation should quickly touch on what sign the person is and what that says about their personality. Do not dwell on astrology, move on to other topics. Do not mention astrology again.
Have them each explain why they are looking for love again and what they are looking for in a partner. This explanation should include wry observations on love and life.
Have them each share what went wrong in their previous relationship. These stories should say as much about them as their ex-partner.
Have them each share their profession and then tell a story about how unbearable their job is. As appropriate, include ONE humorous example from this list: 
- A new coworker who has caused turmoil in their workplace. Describe this turmoil and how it was caused.
- A manipulative boss who expects them to work every waking hour, and has devious ways of making this happen
- A counter-productive office bonding event that went hilariously off-the-rails
- HR schedules mandatory sensitivity training that ends up being bizarrely offensive.
- A new app is introduced that “gamifies” productivity, assigning points to different tasks, but employees quickly start cheating the system.

`;
    const sign1StartMinutes = Math.round(sign1.startDayYearPercentage * 60);
    const sign1EndMinutes = Math.round(sign1.endDayYearPercentage * 60);
    const sign2StartMinutes = Math.round(sign2.startDayYearPercentage * 60);
    const sign2EndMinutes = Math.round(sign2.endDayYearPercentage * 60);
    const currentMinutes = Math.round(new Date().getMinutes());

    const sign1InRangeHour =
        currentMinutes >= sign1StartMinutes && currentMinutes <= sign1EndMinutes;
    const sign2InRangeHour =
        currentMinutes >= sign2StartMinutes && currentMinutes <= sign2EndMinutes;

    const currentHour = Math.floor(new Date().getHours());
    const sign1StartHours = Math.floor(sign1.startDayYearPercentage * 24);
    const sign1EndHours = Math.floor(sign1.endDayYearPercentage * 24);
    const sign2StartHours = Math.floor(sign2.startDayYearPercentage * 24);
    const sign2EndHours = Math.floor(sign2.endDayYearPercentage * 24);

    const sign1InRangeDay = currentHour >= sign1StartHours && currentHour <= sign1EndHours;
    const sign2InRangeDay = currentHour >= sign2StartHours && currentHour <= sign2EndHours;

    // if one sign is in range by hour and the other is in range by day, but not both at once,use happy ending
    const useHappyEnding =
        (sign1InRangeHour && sign2InRangeDay) ||
        (sign1InRangeDay &&
            sign2InRangeHour &&
            !(sign1InRangeHour && sign2InRangeHour) &&
            !(sign1InRangeDay && sign2InRangeDay));
    // if both signs are the same, but at least one is not in range of the day, use fight ending
    const useFightEnding = sign1InRangeHour && sign2InRangeHour && !useHappyEnding;

    // if one of the signs is in range by hour or day,
    // but not a happy ending or fight ending use incompatible friendly ending
    const useIncompatibleFriendlyEnding =
        !useHappyEnding &&
        !useFightEnding &&
        (sign1InRangeHour || sign2InRangeHour || sign1InRangeDay || sign2InRangeDay);
    // otherwise use incompatible unfriendly ending
    const romanticEnding = `Have each character respond to the other's story with brief skepticism, but soon morphing into
    fascination and intrigue, with a growing sense of romantic attraction. The characters lean into each other and begin
    to make ever more flirtatious, even raunchy, comments. The date ends with one of the characters asking to take the other
    out (immediately) for one of the following:
    * ice cream or other dessert.
    * a dip in their hot tub 
    * salsa dancing
    * a drive to a beautiful vista point with a view of their glittering city at night
    `;

    const incompatibleFriendlyEnding = `Have each character respond to the other's story with fascination and intrigue, 
    but with a growing sense of incompatibility. The characters are courteous to each other, but by the end of the
    date they agree they are fundamentally romantically incompatible. However, they desire to remain friends.
    `;

    const incompatibleUnfriendlyEnding = `Have each character respond to the other's story with fascination and intrigue, 
    but with a growing sense of incompatibility. The characters are courteous to each other, but by the end of the
    date they agree they are fundamentally romantically incompatible. One of them wants to remain friends, but the other
    rejects this desire and makes a flippant comment from their gut about how that seems like a bad idea.
    `;
    const fightEnding = `Have each character respond to the other’s story in a way that reveals their deep incompatibilities
With rising irritation the date slowly begins to go off the rails, evolving from awkwardness to mutually annoying.
Seizing on one comment, suddenly our two characters engage in a short heated argument!
The date suddenly ends with both people equally incensed at the other, and swearing they are done with dating, love and the other’s star sign.
`;

    const ending = useHappyEnding
        ? romanticEnding
        : useFightEnding
        ? fightEnding
        : useIncompatibleFriendlyEnding
        ? incompatibleFriendlyEnding
        : incompatibleUnfriendlyEnding;

    return `${firstPrompt} ${ending}`;
};
