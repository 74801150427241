import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './HomeView.module.css';
import ExperimentCard from './ExperimentCard';
import Header from './Header';

const experimentsData = [
    {
        id: 1,
        glyphSrc: 'experiments/first_date/glyph.png',
        title: 'The First Date',
        link: '/first-date',
        description:
            'You decide their star signs, but fate decides if they can stand the idea of seeing each other again.',
        details:
            'Exploring whether entertaining characters and a believable rom-com genre scene can be AI generated based on simple inputs. Script and audio only.',
        partners: [
            { src: 'partners/DSS.svg', alt: 'DSS Logo' },
            { src: 'partners/ElevenLabs.svg', alt: 'Eleven Labs Logo' },
            { src: 'partners/OpenAI.svg', alt: 'OpenAI Logo' },
        ],
    },
];

function PickfordLabs() {
    const navigate = useNavigate();

    const handleCardClick = path => {
        navigate(path);
    };

    return (
        <main className={styles.labsMain}>
            <Header />
            <section className={styles.experimentArea}>
                <div className={styles.sideColumn} />
                <div className={styles.content}>
                    <p className={styles.learnMore}>
                        <span>
                            What&apos;s this all about?{' '}
                            <a
                                href="https://pickfordai.medium.com/pickford-dev-blog-004-f8727b00b3ad"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.learnMoreLink}
                            >
                                Learn more
                            </a>
                        </span>
                    </p>
                    <div className={styles.experiments}>
                        {experimentsData.map(experiment => (
                            <div
                                key={experiment.id}
                                className={styles.clickableCard}
                                onClick={() => handleCardClick(experiment.link)}
                            >
                                <ExperimentCard {...experiment} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.sideColumn} />
            </section>
        </main>
    );
}

export default PickfordLabs;
