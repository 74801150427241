import React from 'react';
import MediaCard from './MediaCard';
import styles from './MediaGrid.module.css';
import { ProjectImplWithStats } from '../../../models/Project';

type MediaGridProps = {
    projects: ProjectImplWithStats[];
    onProjectStatsUpdated?: (updatedProject: ProjectImplWithStats, index: number) => void;
};

function MediaGrid({ projects, onProjectStatsUpdated }: MediaGridProps) {
    return (
        <div className={styles.mediaGrid}>
            {projects.map((project, index) => (
                <MediaCard
                    key={index}
                    project={project}
                    onProjectStatsUpdated={
                        onProjectStatsUpdated
                            ? updatedProject => onProjectStatsUpdated(updatedProject, index)
                            : undefined
                    }
                />
            ))}
        </div>
    );
}

export default MediaGrid;
