import React from 'react';
import { Link } from 'react-router-dom';
import styles from './MediaCard.module.css';
import { ProjectImplWithStats } from '../../../models/Project';
import AudioPlayerComponent from '../AudioPlayerComponent';

type MediaCardProps = {
    project: ProjectImplWithStats;
    onProjectStatsUpdated?: (updatedProject: ProjectImplWithStats) => void;
};

function MediaCard({ project, onProjectStatsUpdated }: MediaCardProps) {
    return (
        <article className={styles.mediaCard}>
            <div className={styles.mediaPreview}>
                <AudioPlayerComponent
                    dss={project}
                    onProjectSaved={onProjectStatsUpdated}
                    useMiniDisplay={true}
                />
            </div>

            <Link
                to={`/first-date/play?id=${project.id}`}
                state={{ dss: project }}
                className={styles.cardContent}
            >
                <h3 className={styles.cardTitle}>{project.title}</h3>
                {project.pairing && (
                    <p className={styles.cardMetadata}>{project?.pairing.replace(/and/g, 'v.')}</p>
                )}{' '}
                {project.creator && <p className={styles.cardMetadata}>By {project.creator}</p>}
                {project.scriptStyle && (
                    <p className={styles.cardMetadata}>{project.scriptStyle} style</p>
                )}
                <p className={styles.cardStats}>
                    <span className={styles.statBold}>{project.days || 0} </span>
                    <span className={styles.statNormal}>days </span>
                    <span className={styles.statBold}>{project.starts || 0} </span>
                    <span className={styles.normal}>views </span>
                </p>
            </Link>
        </article>
    );
}

export default MediaCard;
