import React, { useState, useRef, useEffect } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { BsFillPlayFill, BsFillPauseFill } from 'react-icons/bs';
import styles from './AudioPlayerComponent.module.css';
import { Dialogue, ProjectImplWithStats } from '../../models/Project';
import { useMediaQuery } from 'react-responsive';
import { saveProject as doSaveProject } from '../../utils/backendInterface';
import { StarSign } from '../../utils/starSignInformation';
import { useBackground } from '../../utils/BackgroundContext';

const PLAY_THRESHOLD = parseFloat(process.env.REACT_APP_PLAY_THRESHOLD || '1.0');
const BACKGROUND_MUSIC_VOLUME = parseFloat(process.env.REACT_APP_BACKGROUND_MUSIC_VOLUME || '0.1');

type AudioPlayerComponentProps = {
    dss: ProjectImplWithStats;
    onProjectSaved?: (project: ProjectImplWithStats) => void;
    useMiniDisplay?: boolean;
};

const AudioPlayerComponent = ({
    dss,
    onProjectSaved,
    useMiniDisplay = false,
}: AudioPlayerComponentProps) => {
    const currentLineRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const [outerIndex, setOuterIndex] = useState(0);
    const [innerIndex, setInnerIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const shouldPlayNextLine = useRef(false);
    const [hasStartedPlaying, setHasStartedPlaying] = useState(false);
    const audioPlayerRef = useRef(null);
    const backgroundMusicRef = useRef<HTMLAudioElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isStoryComplete, setIsStoryComplete] = useState(false);

    const isMobile = useMediaQuery({ query: '(max-width: 991px)' }) || useMiniDisplay;

    const { setManualBackground } = useBackground();

    const updatePlayStats = (currentOuterIndex: number, currentInnerIndex: number) => {
        let shouldSave = false;

        if (!dss.extra_data) {
            dss.extra_data = {};
        }

        if (!hasStartedPlaying) {
            dss.extra_data.starts = (dss.extra_data.starts || 0) + 1;
            shouldSave = true;
        }

        const totalDialogues = dss.scenes.reduce(
            (sum, scene) => sum + scene.items.filter(item => item.type === 'Dialogue').length,
            0,
        );
        const totalDialoguesBeforeOuterIndex = dss.scenes
            .slice(0, currentOuterIndex)
            .reduce(
                (sum, scene) => sum + scene.items.filter(item => item.type === 'Dialogue').length,
                0,
            );
        const currentProgress =
            (totalDialoguesBeforeOuterIndex + currentInnerIndex + 1) / totalDialogues;

        if (currentProgress >= PLAY_THRESHOLD) {
            dss.extra_data.plays = (dss.extra_data.plays || 0) + 1;
            shouldSave = true;
        }

        if (shouldSave && dss.isSaved) {
            doSaveProject(dss)
                .then(savedProject => {
                    if (onProjectSaved) {
                        onProjectSaved(savedProject);
                    }
                })
                .catch(error => console.error('Error saving project:', error));
        }
    };

    const playAudio = async () => {
        if (!audioPlayerRef.current?.audio?.current) return;

        try {
            setIsLoading(true);
            const playPromise = audioPlayerRef.current.audio.current.play();
            if (playPromise !== undefined) {
                await playPromise;
                setIsPlaying(true);
                setIsLoading(false);
                if (!useMiniDisplay) setManualBackground('#131313');
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Playback failed:', error);
            setIsPlaying(false);
            setIsLoading(false);
        }
        if (dss.music?.url && backgroundMusicRef.current) {
            backgroundMusicRef.current.volume = BACKGROUND_MUSIC_VOLUME;
            backgroundMusicRef.current.play();
        }
    };

    const pauseAudio = () => {
        audioPlayerRef.current.audio.current.pause();
        // Pause background music
        if (backgroundMusicRef.current) {
            backgroundMusicRef.current.pause();
        }

        setManualBackground('#171434');
    };

    const handleAudioEnded = async () => {
        if (!dss) {
            return;
        }

        if (
            outerIndex === dss.scenes.length - 1 &&
            innerIndex === dss.scenes[outerIndex].items.length - 1
        ) {
            // Story is complete
            setIsStoryComplete(true);
            setIsPlaying(false);
            shouldPlayNextLine.current = false;

            // backgroundMusicRef.current.pause();
            pauseAudio();

            updatePlayStats(outerIndex, innerIndex);
            return;
        }

        if (outerIndex < dss.scenes.length) {
            if (innerIndex < dss.scenes[outerIndex].items.length - 1) {
                setInnerIndex(innerIndex + 1);
                updatePlayStats(outerIndex, innerIndex + 1);
                shouldPlayNextLine.current = true;
                return;
            } else if (outerIndex < dss.scenes.length - 1) {
                setOuterIndex(outerIndex + 1);
                setInnerIndex(0);
                updatePlayStats(outerIndex + 1, 0);
                shouldPlayNextLine.current = true;
                return;
            }
        }

        shouldPlayNextLine.current = false;
    };

    const handlePlayPause = async () => {
        // If story is complete, reset to the beginning
        if (isStoryComplete) {
            setIsStoryComplete(false);
            setOuterIndex(0);
            setInnerIndex(0);
            backgroundMusicRef.current.currentTime = 0;
        }

        if (!audioPlayerRef.current?.audio?.current) return;

        if (isPlaying) {
            pauseAudio();
            shouldPlayNextLine.current = false;
        } else if (!isLoading) {
            updatePlayStats(outerIndex, innerIndex);
            await playAudio();

            setHasStartedPlaying(true);
        }
    };

    const getSignIcon = (sign: StarSign) => {
        if (sign) {
            const IconComponent = sign.icon;
            return <IconComponent className={styles.vsSignIcon} />;
        }
        return null;
    };

    const getPreviousLine = (): Dialogue | null => {
        if (innerIndex > 0) {
            return dss.scenes[outerIndex].items[innerIndex - 1] as Dialogue;
        } else if (outerIndex > 0 && dss.scenes[outerIndex - 1].items.length > 0) {
            return dss.scenes[outerIndex - 1].items[
                dss.scenes[outerIndex - 1].items.length - 1
            ] as Dialogue;
        }
        return null;
    };

    const getNextLine = (): Dialogue | null => {
        if (innerIndex < dss.scenes[outerIndex].items.length - 1) {
            return dss.scenes[outerIndex].items[innerIndex + 1] as Dialogue;
        } else if (
            outerIndex < dss.scenes.length - 1 &&
            dss.scenes[outerIndex + 1].items.length > 0
        ) {
            return dss.scenes[outerIndex + 1].items[0] as Dialogue;
        }
        return null;
    };

    const previousLine = getPreviousLine();
    const currentLine =
        dss.scenes[outerIndex] && (dss.scenes[outerIndex].items[innerIndex] as Dialogue);
    const nextLine = getNextLine();

    return (
        <div className={styles.linesAndAudioWrapper}>
            {dss.music?.url && <audio ref={backgroundMusicRef} src={dss.music.url} />}
            <div
                className={
                    useMiniDisplay ? styles.playerContainerNoLines : styles.scrollableLinesContainer
                }
                ref={scrollContainerRef}
            >
                {!hasStartedPlaying || useMiniDisplay ? (
                    <div className={`${styles.lineDisplay} ${styles.currentLine}`}>
                        <div className={styles.vsDisplay}>
                            <div className={styles.vsSign}>{getSignIcon(dss.firstPersonSign)}</div>
                            <span className={styles.vsText}>V.</span>
                            <div className={styles.vsSign}>{getSignIcon(dss.secondPersonSign)}</div>
                        </div>
                    </div>
                ) : (
                    <>
                        {previousLine && (
                            <div className={`${styles.lineDisplay}`}>
                                <span className={styles.lineCharacter}>
                                    {previousLine.extra_data.character}:{' '}
                                </span>
                                <span className={styles.lineText}>
                                    {previousLine.extra_data.line}
                                </span>
                            </div>
                        )}
                        {currentLine && (
                            <div
                                className={`${styles.lineDisplay} ${styles.currentLine}`}
                                ref={currentLineRef}
                            >
                                <span className={styles.lineCharacter}>
                                    {currentLine.extra_data.character}:{' '}
                                </span>
                                <span className={styles.lineText}>
                                    {currentLine.extra_data.line}
                                </span>
                            </div>
                        )}
                        {nextLine && (
                            <div className={`${styles.lineDisplay}`}>
                                <span className={styles.lineCharacter}>
                                    {nextLine.extra_data.character}:{' '}
                                </span>
                                <span className={styles.lineText}>{nextLine.extra_data.line}</span>
                            </div>
                        )}
                    </>
                )}
            </div>

            <div className={styles.mediaPreview}>
                {dss &&
                    dss.scenes.length > 0 &&
                    dss.scenes[outerIndex] &&
                    dss.scenes[outerIndex].items[innerIndex] && (
                        <AudioPlayer
                            ref={audioPlayerRef}
                            src={
                                (dss.scenes[outerIndex].items[innerIndex] as Dialogue).extra_data
                                    .audio_url ||
                                'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBAAAAABAAEARKwAAIhYAQACABAAZGF0YQQAAAA='
                            }
                            onEnded={handleAudioEnded}
                            onPlay={() => setIsPlaying(true)}
                            onPause={() => setIsPlaying(false)}
                            onPlaying={() => {
                                if (!isPlaying) setIsPlaying(true);
                            }}
                            onWaiting={() => {
                                if (isPlaying) setIsPlaying(false);
                            }}
                            autoPlayAfterSrcChange={true}
                            showJumpControls={false}
                            showFilledVolume={true}
                            customControlsSection={[]}
                            customProgressBarSection={[
                                <label
                                    key="playPause"
                                    className={`${styles.playButton} ${
                                        isLoading ? styles.loading : ''
                                    }`}
                                    onClick={handlePlayPause}
                                >
                                    {isLoading ? (
                                        <BsFillPauseFill />
                                    ) : isPlaying ? (
                                        <BsFillPauseFill />
                                    ) : (
                                        <BsFillPlayFill />
                                    )}
                                </label>,
                                RHAP_UI.CURRENT_TIME,
                                RHAP_UI.PROGRESS_BAR,
                                RHAP_UI.DURATION,
                                RHAP_UI.VOLUME_CONTROLS,
                            ]}
                            {...(isMobile && { customVolumeControls: [] })}
                            className={styles.customAudioPlayer}
                            defaultCurrentTime="00:00"
                            defaultDuration="00:00"
                        />
                    )}
            </div>
        </div>
    );
};

export default AudioPlayerComponent;
