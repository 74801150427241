import {
    TbZodiacAquarius,
    TbZodiacAries,
    TbZodiacCancer,
    TbZodiacCapricorn,
    TbZodiacGemini,
    TbZodiacLeo,
    TbZodiacLibra,
    TbZodiacPisces,
    TbZodiacSagittarius,
    TbZodiacScorpio,
    TbZodiacTaurus,
    TbZodiacVirgo,
} from 'react-icons/tb';
import { IconType } from 'react-icons';

export interface StarSign {
    name: string;
    icon: IconType;
    startDate: Date;
    endDate: Date;
    redFlag: string;
    readonly dateRange: string;
    readonly startDayYearPercentage: number;
    readonly endDayYearPercentage: number;
}

const getDayYearPercentage = (date: Date): number => {
    const normalizedDate = new Date(date);
    normalizedDate.setFullYear(2024); // Use leap year for consistency
    const startOfYear = new Date(2024, 0, 1);
    const dayOfYear = Math.floor(
        (normalizedDate.getTime() - startOfYear.getTime()) / (1000 * 60 * 60 * 24),
    );

    return dayOfYear / 366;
};

class StarSignImpl implements StarSign {
    name: string;
    icon: IconType;
    startDate: Date;
    endDate: Date;
    redFlag: string;

    constructor(name: string, icon: IconType, startDate: Date, endDate: Date, redFlag: string) {
        this.name = name;
        this.icon = icon;
        this.startDate = startDate;
        this.endDate = endDate;
        this.redFlag = redFlag;
    }

    get dateRange(): string {
        return `${this.startDate.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
        })} - ${this.endDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}`;
    }

    get startDayYearPercentage(): number {
        return getDayYearPercentage(this.startDate);
    }

    get endDayYearPercentage(): number {
        return getDayYearPercentage(this.endDate);
    }
}

// Add type for the mapping
type StarSignMap = {
    [key: string]: StarSign;
};

// Convert array to mapping
export const starSigns: StarSignMap = {
    Aquarius: new StarSignImpl(
        'Aquarius',
        TbZodiacAquarius,
        new Date(2024, 0, 20),
        new Date(2024, 1, 18),
        'Impulsive: Acts without thinking.',
    ),
    Pisces: new StarSignImpl(
        'Pisces',
        TbZodiacPisces,
        new Date(2024, 1, 19),
        new Date(2024, 2, 20),
        'Stubborn: Refuses to budge.',
    ),
    Aries: new StarSignImpl(
        'Aries',
        TbZodiacAries,
        new Date(2024, 2, 21),
        new Date(2024, 3, 19),
        'Inconsistent: Hot and cold.',
    ),
    Taurus: new StarSignImpl(
        'Taurus',
        TbZodiacTaurus,
        new Date(2024, 3, 20),
        new Date(2024, 4, 20),
        'Clingy: Overly attached.',
    ),
    Gemini: new StarSignImpl(
        'Gemini',
        TbZodiacGemini,
        new Date(2024, 4, 21),
        new Date(2024, 5, 21),
        'Self-centered: Loves the spotlight.',
    ),
    Cancer: new StarSignImpl(
        'Cancer',
        TbZodiacCancer,
        new Date(2024, 5, 22),
        new Date(2024, 6, 22),
        'Overcritical: Nitpicks constantly.',
    ),
    Leo: new StarSignImpl(
        'Leo',
        TbZodiacLeo,
        new Date(2024, 6, 23),
        new Date(2024, 7, 22),
        'Indecisive: Struggles to choose.',
    ),
    Virgo: new StarSignImpl(
        'Virgo',
        TbZodiacVirgo,
        new Date(2024, 7, 23),
        new Date(2024, 8, 22),
        'Jealous: Easily suspicious.',
    ),
    Libra: new StarSignImpl(
        'Libra',
        TbZodiacLibra,
        new Date(2024, 8, 23),
        new Date(2024, 9, 23),
        'Commitment-phobic: Dodges serious ties.',
    ),
    Scorpio: new StarSignImpl(
        'Scorpio',
        TbZodiacScorpio,
        new Date(2024, 9, 24),
        new Date(2024, 10, 21),
        'Workaholic: Career over love.',
    ),
    Sagittarius: new StarSignImpl(
        'Sagittarius',
        TbZodiacSagittarius,
        new Date(2024, 10, 22),
        new Date(2024, 11, 21),
        'Detached: Emotionally distant.',
    ),
    Capricorn: new StarSignImpl(
        'Capricorn',
        TbZodiacCapricorn,
        new Date(2024, 11, 22),
        new Date(2024, 0, 19),
        'Escapist: Avoids reality.',
    ),
};

// Add getter function
export function getStarSign(name: string): StarSign {
    const sign = starSigns[name];
    if (!sign) {
        throw new Error(`Star sign "${name}" not found`);
    }
    return sign;
}

export const allStarSigns = Object.values(starSigns);
